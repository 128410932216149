<template>
  <v-row style="height: 100%;">
    <v-col cols="12" class="d-flex flex-column align-center justify-center">
      <v-progress-circular
        class="mb-8"
        indeterminate
        size="100"
        color="primary"
      ></v-progress-circular>
      <h3>Autenticando...</h3>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

import { InvitedSystem } from "@/modules/core/enums/invited-system.enum";

export default {
  async mounted() {
    try {
      this.clearLocalstorage();
      const { token } = this.$route.params;
      const data = await this.login(token);
      if (data) {
        this.setHasLoggedUser();
        if (this.isInvitedABRUBA(data)) {
          this.$router.push("/convites-uba");
          return;
        }
        this.$router.push("/");
      }
    } catch (error) {
      if (error.response) {
        this.$router.push("/404");
        this.toggleSnackbar({
          text: error.response.data.detail,
          type: "error"
        });
      }
    }
  },
  methods: {
    ...mapActions(["toggleSnackbar", "setHasLoggedUser"]),
    ...mapActions("inviteds", ["login"]),

    clearLocalstorage() {
      window.localStorage.clear();
    },

    isInvitedABRUBA(data) {
      if (
        !data?.sistemas_convidados?.length ||
        data.sistemas_convidados.some(invitedSystem =>
          InvitedSystem.ABR_SBRHVI.check(invitedSystem)
        )
      ) {
        return false;
      }

      return data.sistemas_convidados.some(invitedSystem =>
        InvitedSystem.ABR_UBA.check(invitedSystem)
      );
    }
  }
};
</script>

<style></style>
